import {
    INITIALIZE_PLAYLIST_REQUREST,
    FETCHED_PLAYLISTS,
    FETCHED_PLAYLIST,
    FAILED_PLAYLIST_REQUEST
} from "../actionTypes/playlist";

import PlaylistService from "../../services/PlaylistService";
import ShowToast from "../../utils/ShowToast";

const initializePlaylistRequest = () => {
    return {
        type: INITIALIZE_PLAYLIST_REQUREST,
    };
};

const fetchedPlaylists = (data) => {
    return {
        type: FETCHED_PLAYLISTS,
        payload: data,
    };
};

const fetchedPlaylist = (data) => {
    return {
        type: FETCHED_PLAYLIST,
        payload: data,
    };
};

const failedPlaylistRequest = (error) => {
    return {
        type: FAILED_PLAYLIST_REQUEST,
        payload: error,
    };
};

export const fetchPlaylists = () => {
    return async (dispatch) => {
        try {
            dispatch(initializePlaylistRequest());
            const { data } = await PlaylistService.fetchPlaylists();
            dispatch(fetchedPlaylists(data));
        } catch ({ response }) {
            dispatch(failedPlaylistRequest(response));
        }
    };
};

export const fetchPlaylist = (gameId, limit, key) => {
    return async (dispatch) => {
        try {
            dispatch(initializePlaylistRequest());
            const { data } = await PlaylistService.fetchPlaylist(gameId, limit, key);
            dispatch(fetchedPlaylist(data));
        } catch (error) {
            console.log(error);
            dispatch(failedPlaylistRequest(error));
        }
    };
};

export const removeSong = (songId) => {
    return async (dispatch) => {
        try {
            const { status } = await PlaylistService.removeSong(songId);
        } catch ({ response }) {
            console.log(response);
        }
    };
};

export const updateSong = (songId, songInput) => {
    return async (dispatch) => {
        try {
            await PlaylistService.updateSong(songId, songInput);
        } catch (error) {
            return ShowToast("Something went Wrong!", "error", null, 3);
        }
    };
};

export const deletePlaylist = (playlistId) => {
    return async (dispatch) => {
        try {
            const { status } = await PlaylistService.deletePlaylist(playlistId);
            if (status === 200) {
                dispatch(fetchPlaylists());
                ShowToast("Playlist Deleted Successfully", "success", null, 3);
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const createPlaylist = (playlistInput) => {
    return async (dispatch) => {
        try {
            await PlaylistService.createPlaylist(playlistInput);
        } catch (error) {
            console.log(error);
            return ShowToast("Something went Wrong!", "error", null, 3);
        }
    };
};

export const updatePlaylist = (playlistId, playlistInput) => {
    return async (dispatch) => {
        try {
            await PlaylistService.updatePlaylist(playlistId, playlistInput);
        } catch ({ response }) {
            console.log(response);
        }
    };
};
