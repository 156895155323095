import React from "react";
import { Row, Col, Typography, Space } from "antd";
import {PrizeIcon, GroupIcon} from "../../utils/Icons";

const { Text } = Typography;

const JamDeckWinner = ({activeGame, winnerName}) => {

    return (
        <Row justify="center" style={{width: '100%'}}>
            <Row justify="center" style={{marginTop: 20}}>
                <Space direction="vertical" size={37} style={{textAlign: 'center', alignItems: 'center'}}>
                    <Text className="game-over-title" style={{marginBottom: 37}}>ROUND OVER</Text>
                    <Row style={{flexDirection: 'column', alignItems: 'center'}}>
                        <Text className="game-over-sub-heading">And the winner of</Text>
                        <Text className="game-over-sub-heading">Round {activeGame?.key + 1} ...</Text>
                    </Row>
                    
                </Space>
            </Row>
            
            <Col span={24} style={{display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 56.98}}>
                <Space direction="vertical" size={9} style={{textAlign: 'center', alignItems: 'center'}}>
                    <GroupIcon style={{width: 78.93, height: 62, fill: 'white'}} />
                    <Text className="game-winner-name">{winnerName || 'No Winner'}</Text>
                    <PrizeIcon style={{fill: '#FFFFFF', fontSize: 23.23}} />
                    <Text className="game-over-prize-text-modal" style={{textAlign: 'center'}}>{activeGame?.prize}</Text>
                </Space>
            </Col>
        </Row>
    );
};

export default JamDeckWinner;
