import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography } from "antd";

import {
    GetHelpSVGIcon,
    UserIconSVG,
    JindoProfileLogo,
    CreateJamSVGIcon,
    AdminButtonSVGIcon,
    ManageSubscriptionSVGIcon
} from "../../utils/Icons";
import ActionButton from "../common/ActionButton";
import UploadImageSplash from "../common/UploadImageSplash";

const { Text } = Typography;

const UserHomeContent = ({
    onProfile,
    onHost,
    onAdmin,
    onPlay,
    isAdmin,
    profileImage,
    handleGetHelp,
    handleUpdateUserImage,
    isLoading,
    onManageSubscription
}) => {
    return (
        <Row>
            <Col className='user-home-header' span={24}>
                <Link to={"/logout"}>
                    <Text
                        className='profile-header-logout-text'
                        style={{ color: "white" }}
                    >
                        LOG OUT
                    </Text>
                </Link>
            </Col>
            <Row style={{ position: "absolute", top: "-8%" }}>
                <JindoProfileLogo style={{ width: 190, height: 175 }} />
            </Row>
            <Row
                justify='center'
                style={{ width: "100%", height: 147, marginTop: 12 }}
            >
                <Col
                    style={{
                        width: 147,
                        height: 147,
                        borderRadius: 100,
                        background: "#FFFFFF",
                    }}
                >
                    <UploadImageSplash
                        onCustomRequest={handleUpdateUserImage}
                        displayImage={profileImage}
                        isLoading={isLoading}
                    />
                </Col>
            </Row>
            <Col xs={24}>
                <Row justify='center'>
                    <Text className='profile-sub-text'>
                        What would you like to do today?
                    </Text>
                </Row>
            </Col>
            <Col className='user-home-actions'>
                <Row justify='center'>
                    <Col span={22} style={{ marginBottom: 12 }}>
                        <ActionButton
                            title='create a jam'
                            className='high-priority-btn'
                            onClick={onHost}
                            prefixIconSVG={CreateJamSVGIcon}
                            iconSize={{ width: 123, height: 100 }}
                            buttonPadding='14px 28px'
                        />
                    </Col>
                    <Col span={22} style={{ marginBottom: 12 }}>
                        <Row justify='space-between' style={{ width: "100%" }}>
                            <Col span={11}>
                                <ActionButton
                                    title='manage profile'
                                    className='high-priority-btn'
                                    onClick={onProfile}
                                    prefixIconSVG={UserIconSVG}
                                    iconSize={{
                                        width: 52,
                                        height: 48,
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                    buttonPadding='6px 18px'
                                    justifyRow='center'
                                />
                            </Col>
                            <Col span={11}>
                                <ActionButton
                                    title='get help'
                                    className='high-priority-btn'
                                    onClick={handleGetHelp}
                                    prefixIconSVG={GetHelpSVGIcon}
                                    iconSize={{
                                        width: 52,
                                        height: 48,
                                        marginRight: 10,
                                        marginLeft: 10,
                                    }}
                                    buttonPadding='5px 18px'
                                    justifyRow='center'
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={22} style={{ marginBottom: 54 }}>
                        <ActionButton
                            title='MANAGE SUBSCRIPTION'
                            className='high-priority-btn'
                            onClick={onManageSubscription}
                            prefixIconSVG={ManageSubscriptionSVGIcon}
                            iconSize={{ width: 59, height: 45 }}
                            buttonPadding='14px 28px'
                            subButtonText='UPDATE PAYMENT METHOD OR CANCEL SUBSCRIPTION'
                        />
                    </Col>

                    {isAdmin && (
                        <Row
                            justify='center'
                            style={{ position: "absolute", bottom: 0 }}
                        >
                            <AdminButtonSVGIcon
                                style={{ width: 114, height: 46 }}
                                onClick={onAdmin}
                            />
                        </Row>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default UserHomeContent;
