import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Grid, Space, Button } from "antd";
import MobileDetect from "mobile-detect";
import { isMobile } from "react-device-detect";
import {
    JamDeckIcon,
    GroupIcon,
    DiskIcon,
    ShareIcon,
    Settings3WheelIcon,
} from "../../../utils/Icons";
import JamDeckCreateContainer from "./JamDeckCreateContainer";
import PlayerPatternInfoContainer from "./PlayerPatternInfoContainer";
import PlayedSongsContainer from "./PlayedSongsContainer";
import SettingContainer from "./SettingContainer";
import InvitePlayerContainer from "./InvitePlayerContainer";
import JamDeckFooter from "../../../components/host/jamDeck/JamDeckFooter";
import JamDeckMediaPlayer from "./JamDeckMediaPlayer";
import JamDeckWinner from "../../../components/modals/JamDeckWinnerModal";
import CountDownModal from "../../../components/modals/CountDownModal";
import SoundEffectContainer from "./SoundEffectContainer";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import ResumeModal from "../../../components/modals/ResumeModal";
import useJamHook from "../../../hooks/useJam";
import useJindoCall from "../../../hooks/useJindoCall";
import useActiveGame from "../../../hooks/useActiveGame";
import useRounds from "../../../hooks/useRounds";
import FallBackErrorMessage from "../../../components/common/FallBackErrorMessage";
import useGameWinner from "../../../hooks/useGameWinner";

const { useBreakpoint } = Grid;

const JamDeckContainer = ({ jamId }) => {
    const {jam, setKeyInJam, endTheJam } = useJamHook();
    const { jindoCall } = useJindoCall();
    const { gameWinner } = useGameWinner();
    const {rounds, updateRounds} = useRounds();
    const {activeGame, nextRound} = useActiveGame();
    const mobileDevice = new MobileDetect(window.navigator.userAgent);
    const targetAppleDevices =
        mobileDevice.is("Iphone") ||
        mobileDevice.is("iPad") ||
        mobileDevice.os() === "AndroidOS" ||
        navigator.platform === "MacIntel";
    
    const [isOnline, setIsOnline] = useState(true);
    const [jamCreateTab, setJamCreateTab] = useState(true);
    const [saveGames, setSaveGames] = useState(false);
    const [disableForCreateJamTab, setDisableForICreateJamTab] = useState(false);
    const [disableForJamStart, setDisableForJamStart] = useState(false);
    const [autoTabSwitch, setAutoTabSwitch] = useState(true);
    const [speakerList, setSpeakerList] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState("default");
    const [speakerVolume, setSpeakerVolume] = useState(0.5);
    const [activeTab, setActiveTab] = useState("1");
    const [activeButton, setActiveButton] = useState("");
    const [songPlayStatus, setSongPlayStatus] = useState("pause");
    const [playPauseEffect, setPlayPauseEffect] = useState(false);
    const [countDownModalVisible, setCountDownModalVisible] = useState(false);
    const [winnerModalActive, setWinnerModalActive] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [resumeModal, setResumeModal] = useState(true);
    const [audioCtxState, setAudioCtxState] = useState(true);
    const [jamEditableMode, setJamEditableMode] = useState(false);
    const [isWinnerSongEnded, setIsWinnerSongEnded] = useState(false);

    const dimension = useBreakpoint();

    const lobbySoundRef = useRef(new Audio());
    const jamEndSoundRef = useRef(new Audio());
    const countDownRef = useRef(new Audio());
    const winnerSoundRef = useRef(new Audio());
    const somebodyCalledJindo = useRef(new Audio());
    const verifying = useRef(new Audio());

    lobbySoundRef.current.loop = true;
    jamEndSoundRef.current.loop = true;

    useEffect(() => {
        window.addEventListener('online', () => setIsOnline(true));
        window.addEventListener('offline', () => setIsOnline(false));

        return () => {
            window.removeEventListener('online', () => setIsOnline(true));
            window.removeEventListener('offline', () => setIsOnline(false));
        }
    }, [])

    useEffect(() => {
        (async () => {
            // await navigator.mediaDevices.getUserMedia({ audio: true });
            await navigator?.mediaDevices
                ?.enumerateDevices()
                ?.then((devices) => {
                    const speakerList = devices.filter(
                        (device) =>
                            device.kind === "audiooutput" && device.label !== ""
                    );
                    setSpeakerList(speakerList);
                });
        })();
        return () => {
            setSpeakerList([])
        }
    }, []);

    useEffect(() => {
        lobbySoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}jindoLobbyNew.mp3`;
        jamEndSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}gameEnd.mp3`;
        countDownRef.current.src = `${process.env.SOUND_EFFECTS_URL}countdown.mp3`;
        somebodyCalledJindo.current.src = `${process.env.SOUND_EFFECTS_URL}somebodyCalledJindo.mp3`;
        verifying.current.src = `${process.env.SOUND_EFFECTS_URL}verifying.mp3`;
        winnerSoundRef.current.src = `${process.env.SOUND_EFFECTS_URL}winner.mp3`;
        if (!isMobile) {
            lobbySoundRef.current.play().catch(r => setResumeModal(true));
        }
        document.addEventListener("visibilitychange", () => {
            if (isMobile && !countDownModalVisible) {
                if (document.visibilityState === "visible") {
                    if(!document.getElementsByClassName("game-splash-modal")[0]) {
                        setAudioCtxState(true);
                        setResumeModal(true);
                    }
                } else {
                    setResumeModal(false);
                    setSongPlayStatus("pause");
                    lobbySoundRef.current.pause();
                }
            }
        });
    }, []);

    useEffect(() => {
        if (
            !!window.chrome &&
            (!!window.chrome.webstore || !!window.chrome.runtime) &&
            !isMobile
        ) {
            lobbySoundRef.current.setSinkId(selectedSpeaker);
            jamEndSoundRef.current.setSinkId(selectedSpeaker);
        }
    }, [selectedSpeaker]);

    useEffect(() => {
        lobbySoundRef.current.volume = speakerVolume * 0.2;
        jamEndSoundRef.current.volume = speakerVolume * 0.2;
    }, [speakerVolume]);

    useEffect(() => {
        if (!jamEditableMode && gameWinner?.winner?.isFinished) {
            setActiveTab("2");
            setSongPlayStatus("pause");
            if (!gameWinner?.winner?.name) {
                setIsWinnerSongEnded(true);
                const filteredGames = rounds.filter(
                    (game) => game.gameId !== gameWinner?.winner?.gameId && game.status === 'STARTING_SOON');
                if(filteredGames.length > 0) {
                    lobbySoundRef.current.play();
                } else {
                    jamEndSoundRef.current.play();
                }
                
            }
        }
    }, [gameWinner, rounds, jamEditableMode]);

    useEffect(() => {
        if (jam?.hasOwnProperty("jamId") && autoTabSwitch) {
            if (jam.startingSoonGames && !jam.startedGame) {
                statingSoonGameTab();
                setAutoTabSwitch(false);
            }

            if (!jam.startingSoonGames && !jam.endedGame && !jam.startedGame) {
                setActiveTab("1");
                setJamCreateTab(true);
                setDisableForICreateJamTab(true);
                setActiveButton("saveJam");
                setDisableForJamStart(false);
            }

            if (jam.startedGame) {
                startedGameTab();
                setAutoTabSwitch(false);
            }
            
        } 
    }, [jam, autoTabSwitch]);

    useEffect(() => {
        if (jindoCall?.playerCalledJindo) {
            window.scrollTo(0, 0);
        }
    }, [jindoCall]);

    const handleGameStart = () => {
        setCountDownModalVisible(true);
        const updatedRounds = rounds.map(round => {
            if(round.gameId === activeGame.gameId) {
                round.status = 'STARTED';
            }
            return round;
        })
        updateRounds(updatedRounds);
        startedGameTab();
        setKeyInJam('startingSoonGames', false);
        setKeyInJam('startedGame', true);
    };

    const startedGameTab = () => {
        setActiveTab("1");
        setJamCreateTab(true);
        setDisableForICreateJamTab(false);
        setActiveButton("songStreaming");
        setDisableForJamStart(false);
    }

    const statingSoonGameTab = () => {
        setActiveTab("4");
        setJamCreateTab(false);
        setActiveButton("jamStart");
        setDisableForICreateJamTab(false);
        setDisableForJamStart(true);
    }

    const handleNextRound = () => {
        lobbySoundRef.current.pause();
        jamEndSoundRef.current.pause();
        lobbySoundRef.current.play();
        setKeyInJam('startingSoonGames', true);
        setKeyInJam('startedGame', false);
        setSongPlayStatus("pause");
        setIsWinnerSongEnded(false);
        setWinnerModalActive(true);
        nextRound(gameWinner?.winner).then(() => {
            statingSoonGameTab();
            setWinnerModalActive(false);
            setAutoTabSwitch(true);
        });
    };

    const handleEndJam = () => {
        lobbySoundRef.current.pause();
        jamEndSoundRef.current.pause();
        endTheJam().then(async () => {
            await nextRound(gameWinner?.winner,true);
            setKeyInJam('isFinished', true);
            window.location = `/jamDeckEnd/#${jamId}`;
        });
    };

    const handleCloseResumeModal = () => {
        setResumeModal(false);
        if (audioCtxState) {
            countDownRef.current.play();
            countDownRef.current.pause();
            somebodyCalledJindo.current.play();
            somebodyCalledJindo.current.volume = 0;
            somebodyCalledJindo.current.pause();

            verifying.current.play();
            verifying.current.pause();

            winnerSoundRef.current.play();
            winnerSoundRef.current.pause();

            lobbySoundRef.current.pause();
            lobbySoundRef.current.play();
            lobbySoundRef.current.volume = 0.25;
            setAudioCtxState(false);
        }
    };

    const handleTabChange = (value) => {
        setActiveTab(value);
        setJamCreateTab(value == 1);
    };

    /** 
     * CONCERN: custom hooks cannot be used here since the CollaborationProvider is not wrapping this component. 
     * TODO: add a wrapper container to the root containers, allowing the use of hooks in all the root containers
     */

    return ( 
        <Row align='top' className='jam-deck-container' justify='center'>
            {!isOnline && <FallBackErrorMessage message={'Your Connection is disconnected.'}/>}
            <CountDownModal visible={countDownModalVisible} updateCDModalState={() => {}} isHost={true}/>
            <ResumeModal
                visible={resumeModal}
                onOk={handleCloseResumeModal}
                content={
                    !isMobile
                        ? 'THANKS FOR HOSTING JINDO ON YOUR WEB DEVICE. CLICK TO CONTINUE'
                        : 'THANKS FOR HOSTING JINDO ON YOUR MOBILE DEVICE. CLICK TO CONTINUE.'
                }
                subContent={''}
            />
            <ConfirmationModal
                visible={confirmationModal}
                onCancel={() => setConfirmationModal(false)}
                onOk={() => {
                    setConfirmationModal(false);
                    handleEndJam();
                }}
                content={'ARE YOU SURE YOU WANT TO END THE GAME?'}
                subContent={'This will remove all rounds and players and bring you back to your dashboard'}
                buttonText2={'yes - end game'}
                buttonText={'no - cancel'}
            />
            <Col span={24}>
                {jindoCall?.playerCalledJindo && (
                    <Col
                        offset={8}
                        span={7}
                        style={{
                            top: 55,
                            left: dimension.xs ? -66 : 0,
                            zIndex: 1,
                        }}
                        className='players-called-jindo-tooltip'
                    >
                        <span className='players-called-jindo-tooltiptext'>
                            {jindoCall?.playerCalledJindo.split(" ")[0].substring(0, 13)} CALLED JINDO!
                        </span>
                    </Col>
                )}
                <div className='jam-deck-container-content'>
                    <Space
                        style={{
                            width: 375,
                            justifyContent: "space-between",
                            padding: "0 10px",
                        }}
                        size={0}
                    >
                        <Button
                            type='link'
                            onClick={() => handleTabChange("1")}
                            icon={<JamDeckIcon className='jam-deck-tab-icon' />}
                            className={`jam-deck-tab-button ${
                                activeTab == "1"
                                    ? "jam-deck-tab-button-active"
                                    : ""
                            }`}
                        >
                            Jam Deck
                        </Button>
                        <Button
                            type='link'
                            onClick={() => handleTabChange("2")}
                            className={`jam-deck-tab-button ${
                                activeTab == "2"
                                    ? "jam-deck-tab-button-active"
                                    : ""
                            }`}
                            icon={
                                <GroupIcon
                                    style={{
                                        opacity: disableForCreateJamTab,
                                    }}
                                    className='jam-deck-tab-icon'
                                />
                            }
                            disabled={disableForCreateJamTab || jamEditableMode}
                        >
                            Players
                        </Button>
                        <Button
                            type='link'
                            icon={
                                <DiskIcon
                                    style={{
                                        opacity: disableForCreateJamTab && 0.5,
                                    }}
                                    className='jam-deck-tab-icon'
                                />
                            }
                            onClick={() => handleTabChange("3")}
                            className={`jam-deck-tab-button ${
                                activeTab == "3"
                                    ? "jam-deck-tab-button-active"
                                    : ""
                            }`}
                            disabled={
                                disableForCreateJamTab ||
                                jamEditableMode ||
                                disableForJamStart
                            }
                        >
                            Songs
                        </Button>
                        <Button
                            type='link'
                            icon={<ShareIcon className='jam-deck-tab-icon' />}
                            onClick={() => handleTabChange("4")}
                            className={`jam-deck-tab-button ${
                                activeTab == "4"
                                    ? "jam-deck-tab-button-active"
                                    : ""
                            }`}
                            disabled={disableForCreateJamTab || jamEditableMode}
                        >
                            Invite
                        </Button>
                        <Button
                            type='link'
                            icon={
                                <Settings3WheelIcon className='jam-deck-tab-icon' />
                            }
                            onClick={() => handleTabChange("5")}
                            className={`jam-deck-tab-button ${
                                activeTab == "5"
                                    ? "jam-deck-tab-button-active"
                                    : ""
                            }`}
                        >
                            Settings
                        </Button>
                    </Space>
                    {activeTab === "1" ? (
                        <>
                            {jam && <JamDeckCreateContainer
                                jamId={jamId}
                                games={rounds}
                                setGames={updateRounds}
                                jam={jam}
                                tabActive={jamCreateTab}
                                saveGames={saveGames}
                                setSaveGames={(val) => setSaveGames(val)}
                                setActiveTab={(value) => setActiveTab(value)}
                                activeGame={activeGame}
                                setJamEditableMode={setJamEditableMode}
                                setKeyInJam={setKeyInJam}
                                onAutoTabSwitch={() => setAutoTabSwitch(true)}
                            />}
                        </>
                    ) : activeTab === "2" ? (
                        <>
                            {!gameWinner?.winner?.isFinished && <PlayerPatternInfoContainer
                                gameId={activeGame?.gameId}
                            />}
                            {gameWinner?.winner?.isFinished && <JamDeckWinner
                                activeGame={activeGame}
                                winnerName={gameWinner?.winner?.name}
                            />}
                        </>
                    ) : activeTab === "3" ? (
                        <>
                            <PlayedSongsContainer activeGame={activeGame} />
                        </>
                    ) : activeTab === "4" ? (
                        <>
                            {activeGame?.gameId && <InvitePlayerContainer
                                jam={jam}
                                activeGame={activeGame}
                            />}
                        </>
                    ) : activeTab === "5" ? (
                        <>
                            <SettingContainer
                                speakerList={speakerList}
                                setSpeaker={(val) => setSelectedSpeaker(val)}
                                selectedSpeaker={selectedSpeaker}
                                speakerVolume={speakerVolume}
                                setSpeakerVolume={(val) =>
                                    setSpeakerVolume(val)
                                }
                                onJamEnd={() => setConfirmationModal(true)}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </Col>
            <Col
                span={24}
                className='jam-submit-btn-container'
                style={{ justifyContent: "center", width: "auto" }}
            >
                <JamDeckFooter
                    onJamStart={handleGameStart}
                    onJamSave={() => setSaveGames(true)}
                    activeButton={activeButton}
                    displayButton={songPlayStatus}
                    buttonText={rounds.filter(game => game.gameId !== activeGame?.gameId && game.status === 'STARTING_SOON').length > 0 ? 'NEXT ROUND' : 'END JAM'}
                    handleNextRound={handleNextRound}
                    handleEndJam={handleEndJam}
                    isWinnerSongEnded={isWinnerSongEnded}
                    gameId={activeGame?.gameId}
                    isGameEnded={!jamEditableMode && (gameWinner?.winner?.isFinished || winnerModalActive)}
                    jamEditableMode={jamEditableMode}
                />
                {activeGame?.gameId && <JamDeckMediaPlayer
                    activeGame={activeGame}
                    gameWinner={gameWinner?.winner}
                    songPlayStatus={songPlayStatus}
                    jamId={jam && jam.jamId}
                    playPauseEffect={playPauseEffect}
                    togglePlayPauseEffect={() => setPlayPauseEffect(false)}
                    speakerVolume={speakerVolume}
                    lobbySoundRef={lobbySoundRef}
                    selectedSpeaker={selectedSpeaker}
                    onTogglePlayPause={() => {
                        setPlayPauseEffect(true);
                        if (songPlayStatus === "play") {
                            setSongPlayStatus("pause");
                        }
                        if (songPlayStatus === "pause") {
                            setSongPlayStatus("play");
                        }
                    }}
                    onJamEnd={() => setConfirmationModal(true)}
                    activeButton={activeButton}
                />}
            </Col>
            
            {activeGame?.gameId && !resumeModal &&
                <SoundEffectContainer
                    countDownModalVisible={countDownModalVisible}
                    toggleCountDownModalVisible={() =>
                        setCountDownModalVisible(false)
                    }
                    togglePlay={() => setSongPlayStatus("play")}
                    speakerVolume={speakerVolume}
                    lobbySoundRef={lobbySoundRef}
                    jamEndSoundRef={jamEndSoundRef}
                    games={rounds}
                    activeGame={activeGame}
                    selectedSpeaker={selectedSpeaker}
                    countDownRef={countDownRef}
                    somebodyCalledJindo={somebodyCalledJindo}
                    verifying={verifying}
                    winnerSoundRef={winnerSoundRef}
                    updateWinnerSongEnded={() => setIsWinnerSongEnded(true)}
                    gameWinner={gameWinner}
                />
            }
        </Row>
    );
};

// JamDeckContainer.whyDidYouRender = true

export default JamDeckContainer;
