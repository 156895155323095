import React, { useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";

const { Text } = Typography;
const patternsArr = [
    {
        name: "Single",
        pattern: [1, 2, 3, 4, 5],
    },
    {
        name: "Double",
        pattern: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
        row: 2,
        column: 2,
        name: "Postage Stamp",
        pattern: [1, 2, 6, 7],
    },
    {
        name: "4 Corners",
        pattern: [1, 5, 21, 25],
    },
    {
        name: "X",
        pattern: [1, 7, 13, 19, 25, 5, 9, 17, 21],
    },
    {
        name: "Blackout",
        pattern: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25,
        ],
    },
];

const renderGridLines = (index, pattern = [], color = "white") => {
    let row = [];
    for (const col of Array(5).keys()) {
        if (pattern.includes(index++)) {
            row.push(
                <Col
                    key={index}
                    className='tile-pattern-active-box'
                    style={{ backgroundColor: color }}
                ></Col>
            );
        } else {
            row.push(
                <Col key={index} className='tile-pattern-normal-box'></Col>
            );
        }
    }
    return (
        <Row key={index} justify='center'>
            {row}
        </Row>
    );
};

const renderPatternGrid = (
    color = "",
    currentPattern
) => {
    const patternGrid = [];
    for (let index of [1, 6, 11, 16, 21]) {
        patternGrid.push(
            renderGridLines(index, currentPattern?.pattern || [], color)
        );
    }
    return patternGrid;
};

const TilePatternGrid = ({
    pattern = false,
    color = "",
    animatePatterns = [],
}) => {
    const [currentPattern, setCurrentPattern] = useState({
        name: "",
        pattern: [],
    });
    const [intervalSet, setIntervalSet] = useState(false);

    useEffect(() => {
        if(pattern && currentPattern?.name !== pattern) {
            let ptr = patternsArr.find((ptr) => ptr.name === pattern);
            setCurrentPattern(ptr);
        }
        setIntervalSet(false);
    }, [pattern, currentPattern]);

    useEffect(() => {
        let intervalId;
        if (animatePatterns.length > 0 && !intervalSet) {
            setIntervalSet(true);
            intervalId = setInterval(() => {
                const random = Math.floor(
                    Math.random() * animatePatterns.length - 1
                );
                setCurrentPattern({
                    name: pattern,
                    pattern: animatePatterns[random > 0 ? random : 0],
                });
            }, 10000);
        }

        return () => {
            clearInterval(intervalId)
        }
    }, [animatePatterns]);

    const getPatternName = (pattern) => {
        if(pattern === 'Single') return 'ANY SINGLE LINE';
        if(pattern === 'Double') return 'ANY 2 LINES';
        if(pattern === 'Postage Stamp') return 'BOX 4 ANYWHERE';
        return pattern;
    }

    return (
        <Row justify='center' className='tile-pattern'>
            <Col>{renderPatternGrid(color, currentPattern)}</Col>
            <Col span={24}>
                <Text className='tile-pattern-name'>
                    
                    {getPatternName(currentPattern?.name)}
                </Text>
            </Col>
        </Row>
    );
};

export default TilePatternGrid;
