import { v4 as uuidv4 } from "uuid";
import AuthService from "./AuthService";

import Http from "./HttpService";

export default class PlaylistService {
    static async fetchPlaylists() {
        return await Http.get(`/playlist/fetch/all`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async fetchPlaylist(playlistId, limit, key, gameId) {
        return await Http.post(
            `/playlist/fetch/${playlistId}?limit=${limit}&key=${key}`,
            {gameId},
            { headers: { authorization: localStorage.getItem("auth-token") } }
        );
    }

    static async updateSong(songId, data) {
        return await Http.post(`/song/update/${songId}`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async removeSong(songId) {
        return await Http.delete(`/song/remove/${songId}`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async createPlaylist(data) {
        return await Http.post(`/playlist/add/?id=${uuidv4()}`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async updatePlaylist(playlistId, data) {
        return await Http.post(`/playlist/update/${playlistId}`, data, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }

    static async deletePlaylist(playlistId) {
        return await Http.delete(`/playlist/${playlistId}`, {
            headers: { authorization: localStorage.getItem("auth-token") },
        });
    }
}
