import React from "react";
import { Row, Button } from "antd";

import Setting from "../../../components/host/jamDeck/Setting";

const SettingContainer = ({
  speakerList,
  setSpeaker,
  selectedSpeaker,
  speakerVolume,
  setSpeakerVolume,
  onJamEnd
}) => {
  const handleSpeakerChange = (val) => {
    setSpeaker(val);
  };

  const handleVolumeUp = () => setSpeakerVolume(v => v === 1 ? v : Number(Number(v + 0.1).toFixed(1)));
  const handleVolumeDown = () => setSpeakerVolume(v => v === 0 ? v : Number(Number(v - 0.1).toFixed(1)));

  return (
    <Row justify="center" className="jam-deck-create-container">
      <Setting
        speakerList={speakerList}
        handleSpeakerChange={handleSpeakerChange}
        speaker={selectedSpeaker}
        speakerVolume={speakerVolume}
        setSpeakerVolume={setSpeakerVolume}
        handleVolumeUp={handleVolumeUp}
        handleVolumeDown={handleVolumeDown}
      />

      <Row style={{ margin: "45px 40px", flex: 1 }}>
        <Button
          className="end-jam-btn"
          onClick={onJamEnd}
        >
          END JAM
        </Button>
      </Row>

    </Row>
  );
};

export default SettingContainer;
