import { useEffect, useState } from "react";
import useRound from "./useRound";

export default function useRoundPace(roundId) {
    const [pace, _setPace] = useState(30);
    const { setFieldInRound, getFieldFromRound } = useRound(roundId);

    useEffect(() => {
      // sync pace with the value in rounds map in document
      _setPace(getFieldFromRound('pace'))
    }, [roundId])

    /**
     * Sets pace to a given value
     * @param {number} value - New value for the pace
     */
    const setPace = (value) => {
      setFieldInRound('pace', value)
      _setPace(value)
    }

    return {pace, setPace}
}