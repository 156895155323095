import { HocuspocusProvider } from "@hocuspocus/provider"
import { useCallback, useState, useMemo, useRef, useEffect } from "react"
import { isReadOnly, isProviderNameMatching, isProviderTypeAvailable } from "../utils/hocuspocus"
import { CollaborationContext } from "../context/collaborationContext"
import * as Y from 'yjs'
import React from "react"
import { useUser } from "../context/userContext"
import { AwarenessContext } from "../context/awarenessContext"
import JamService from "../services/JamService";

/**
 * Provides all the hooks and awareness for hocuspocus
 * @param {string} jamId - The active jam id (used in jam provider)
 * @param {boolean} isHost - true if the user is a host
 */
function CollaborationProvider({children, jamId, isHost}) {
  const providersRef = useRef({})
  const user = useUser()
  const [connections, _setConnections] = useState([])

  const updateConnections = ({states}) => {
    const updatedConnections = states.map(({ user }) => ({ id: user.id, name: user.name, isHost: user.isHost }))
    if (states.length !== updatedConnections.length) {
      _setConnections(updatedConnections)
    }
    
  }

  const initJamWithDefaults = (jamMap, host, jamId, jam = {}) => {
    jamMap.set('endedGame', jam?.endedGame || false)
    jamMap.set('isFinished', jam?.isFinished || false)
    jamMap.set('jamCode', jam?.jamCode || '')
    jamMap.set('jamId', jamId)
    jamMap.set('jamTime', jam?.jamTime || '0')
    jamMap.set('jamType', 'IN-PERSON')
    jamMap.set('startedGame', jam?.startedGame || false)
    jamMap.set('startingSoonGames', jam?.startingSoonGames || false)
    jamMap.set('status', jam?.status || true)
    jamMap.set('title', jam?.title || '')
    jamMap.set('host', {id: host.userId, name: host.userName, connected: true})
  }

  const initJamRoundsWithDefaults = (roundsMap, rounds = []) => {
    rounds.forEach(round => {
      round.patternName = round?.winningPattern?.name || round.patternName
      round.patternId = round?.winningPattern?.patternId || round.patternId
      roundsMap.set(round.key.toString(), round)
    })
  }

  /** handles provider instantiation and awareness change 
   * @param {'jam' | 'game'} type - The type of provider to get  
  */
  const getProvider = useCallback((type, gameId) => {
    // names format is `type.uuid` for unique identification of document
    const name = `${type}.${type === 'jam' ? jamId : gameId}`
    const providerNameMatches = isProviderNameMatching(providersRef.current, name)
    const providerTypeAvailable = isProviderTypeAvailable(providersRef.current, type)
    const readOnly = isReadOnly(type, isHost)
    
    // return the existing provider if name matches
    if (providerNameMatches) {
      return providersRef.current[name].provider
    }
    const yDoc = new Y.Doc({guid: name});
    const provider = new HocuspocusProvider({
      url: process.env.HOCUSPOCUS_URL,
      name,
      parameters: { readOnly }, // TODO (Optional): use this parameter (boolean) on backend to enable/disable read only
      document: yDoc
    })

    provider.on("synced", ({state}) => syncedState(state, type, isHost, yDoc));

    if (type === 'game') {
      // user information in awareness to handle connectivity
      provider.setAwarenessField('user', {
        id: isHost ? user.userId : user.playerId,
        name: isHost ? user.userName : user.name,
        isHost,
      })

      provider.on('awarenessChange', updateConnections)
    } 

    // add the instantiated provider into the available providers
    providersRef.current[name] = { provider, readOnly }
    
    // delete the old provider of the same type to avoid duplication
    if (providerTypeAvailable) {
      const oldProviderName = Object.keys(providersRef.current).find((providerName) => {
        const existingType = providerName.split('.')[0]
        return existingType === type
      })

      const oldProvider = providersRef.current[oldProviderName].provider
      oldProvider.disconnect()
      oldProvider.destroy()
    
      delete providersRef.current[oldProviderName]
    }
    
    return provider
  }, [providersRef, jamId, isHost, user])

  const syncedState = (state, type, isHost, yDoc) => {
    
    if (!isHost) {
      // generate empty card layouts for connected players
      const playersMap = yDoc.getMap('players')

      if (Array.from(playersMap.values()).filter(player => player.id === user.playerId).length === 0) {
        playersMap.set(
          user.playerId,
          { 
            id: user.playerId, 
            name: user.name, 
            connected: true, 
            cardLayout: [], 
            selectedPattern: [],
            cdModal: true,
            cardAnimate: true
          }
        )
      }
    }

    if (type === 'jam' && isHost) {
      const jamMap = yDoc.getMap('jam')
      const roundsMap = yDoc.getMap('rounds')
 
      if(Object.keys(Object.fromEntries(jamMap.entries())).length === 0) {
        JamService.fetchJamByJamId({jamId}).then((response) => {
          if (response.status === 200) {
            const {jam, games} = response.data;
            if (games.length === 0) {
              games.push({
                  key: 0,
                  gameId: undefined,
                  pace: 30,
                  patternName: 'Single',
                  patternId: '0b867d34-6d90-41dd-9ec9-30e38eda4cdc',
                  playlistId: undefined,
                  status: 'STARTING_SOON',
                  prize: null,
                  displaySong: true,
                  numberOfSongs: 50,
                  gameTime: getEstimatedGameTime(30, 'Single', 50),
              })
            }
            initJamWithDefaults(jamMap, user, jamId, jam)
            initJamRoundsWithDefaults(roundsMap, games)
          }
        }).catch(() => {
          initJamWithDefaults(jamMap, user, jamId)
        });
      } 
      
    }

    
  }

  const contextValue = useMemo(() => ({ getProvider }), [getProvider])

  useEffect(() => {
    return () => {
      const name = Object.keys(providersRef.current).find((key) => key.includes('game'))
      const jamName = Object.keys(providersRef.current).find((key) => key.includes('jam'))

      if (jamName && providersRef.current) {
        providersRef.current[jamName].provider.off('synced', 
        ({state}) => syncedState(state, 'jam', isHost, providersRef.current[jamName].document))
      }
      if (name && providersRef.current) {
        providersRef.current[name].provider.off('awarenessChange', updateConnections)
      } 
    }
  }, [])

  return (
    <CollaborationContext.Provider value={contextValue}>
      <AwarenessContext.Provider value={connections}>
        {children}
      </AwarenessContext.Provider>
    </CollaborationContext.Provider>
  )
}
// CollaborationProvider.whyDidYouRender = true
export default CollaborationProvider;