import React, { useEffect, useState } from "react";
import { Col, Button } from "antd";
import usePlayers from "../../../hooks/usePlayers";

const JamDeckFooter = ({
    onJamStart,
    onJamSave,
    activeButton,
    buttonText,
    gameId,
    handleNextRound,
    handleEndJam,
    isWinnerSongEnded,
    isGameEnded,
    jamEditableMode
}) => {
    let activePlayersCount = 0;
    // const [buttonText, setButtonText] = useState('NEXT ROUND');

    // useEffect(() => {
    //     if(isGameEnded) {
    //         getButtonText();
    //     }
    // }, [isGameEnded])

    // const getButtonText = () => {
    //     const filteredGames = games.filter(game => game.gameId !== gameId && game.status === 'STARTING_SOON');

    //     if(filteredGames.length > 0) {
    //         setButtonText('NEXT ROUND')
    //     } else {
    //         setButtonText('END JAM')
    //     }
    // }
    
    if(gameId) {
        const {activePlayersCount: activePlayer} = usePlayers(gameId)
        activePlayersCount = activePlayer
    }
    
    return (
        <>
            {!isGameEnded && !jamEditableMode && activeButton === 'saveJam' &&
            <Col span={24} className='jam-submit-btn-container' style={{position: 'relative'}} >
                <Button
                    className='jam-deck-create-button'
                    block
                    onClick={onJamSave}
                >
                    SAVE JAM + INVITE PLAYERS
                </Button>
            </Col>}
            {!isGameEnded && !jamEditableMode && activeButton === 'jamStart' &&
            <Col span={24} className='jam-submit-btn-container' style={{position: 'relative'}} >
                <Button
                    className='jam-deck-create-button'
                    block
                    onClick={onJamStart}
                    disabled={activePlayersCount === 0}
                >
                    LET’S START JAMMING!
                </Button>
            </Col>}
            {isGameEnded && !jamEditableMode &&
            <Col span={24} className='jam-submit-btn-container' style={{position: 'relative'}}>
                <Button block className="jam-end-primary-btn"  
                    onClick={() => {
                        if(buttonText === 'NEXT ROUND') {
                            handleNextRound();
                        } 
                        if(buttonText === 'END JAM') {
                            handleEndJam();
                        }
                    }}
                    disabled={!isWinnerSongEnded}
                >
                    {buttonText}
                </Button>
            </Col>}
        </>
    );
};

export default JamDeckFooter;
