import React, { useEffect, useState } from "react";
import { Input, Row, Col, Typography, Button } from "antd";
import GameForm from "./GameForm";
import { PlusOutlined } from "@ant-design/icons";
import { CrossIcon } from "../../../utils/Icons";
import ConfirmationModal from "../../modals/ConfirmationModal";
import GameService from "../../../services/GameService";
import ShowToast from "../../../utils/ShowToast";

const { Text } = Typography;

const JamDeckCreateForm = (props) => {
    const {
        games,
        gameInfoUpdateHandler,
        onGameDelete,
        onGameAdd,
        jamTime,
        jamName,
        jamCode,
        updateJamName,
        tabActive,
        handleEditableRound,
        editableRound,
        activeGame,
        onCancelActiveRndKey,
        setOnCancelActiveRndKey,
    } = props;

    const [currentRound, setCurrentRound] = useState("0");
    const [game, setGame] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);

    useEffect(() => {
        if (activeGame?.hasOwnProperty("key")) {
            setCurrentRound(`${activeGame?.key}`);
            setOnCancelActiveRndKey();
        }
    }, [activeGame, onCancelActiveRndKey]);

    useEffect(() => {
        if (games.length > 0) {
            setGame(games[currentRound]);
        }
    }, [games, currentRound]);

    const handleAddRound = () => {
        if (games.length < 10) {
            setCurrentRound(`${Number(games.length)}`);
            onGameAdd();
        }
    };

    const handleDeleteRound = (key) => {
        if (games.length > 1) {
            const targetGame = games.find((game) => game.key == currentRound);
            if (targetGame?.status != "STARTED") {
                let updatedGames = games.filter(
                    (game) => game.key != currentRound
                );
                updatedGames = updatedGames.map((game, index) => {
                    game.key = index;
                    return game;
                });

                if (targetGame?.gameId) {
                    GameService.updateGame({
                        ...targetGame,
                        status: "SKIPPED",
                    })
                } 

                onGameDelete(updatedGames);
                setConfirmationModal(false);
                setCurrentRoundFnc(updatedGames);
            }
        }
    };

    const setCurrentRoundFnc = (updatedGames) => {
        if (currentRound != "0") {
            if (updatedGames.length < Number(currentRound)) {
                setCurrentRound(currentRound);
            } else {
                setCurrentRound(`${Number(currentRound) - 1}`);
            }
        }
    }

    const gameUpdateHandler = (value) => {
        games[currentRound] = { ...games[currentRound], ...value };
        gameInfoUpdateHandler(games);
    };

    const onTabChange = (key) => {
        setCurrentRound(key);
        setGame(games[key]);
    };

    return (
        <>
            <ConfirmationModal
                visible={confirmationModal}
                onCancel={() => setConfirmationModal(false)}
                onOk={() => {
                    handleDeleteRound(currentRound);
                }}
                content={'ARE YOU SURE YOU WANT TO REMOVE'}
                subContent={`round ${Number(currentRound) + 1}?`}
                buttonText2={'yes - remove round'}
                buttonText={'no - cancel'}
            />
            <Row justify='center' className='jam-deck-create-container'>
                <Col span={24}>
                    <Col span={24} style={{ textAlign: "center" }}>
                        {jamCode ? (
                            <Text className='jam-title-text'>{jamName}</Text>
                        ) : (
                            <Input
                                placeholder='Enter Your Jam Name Here'
                                className='jam-deck-input-field-main'
                                value={jamName}
                                onChange={updateJamName}
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        <Text className='jam-create-form-text'>
                            {jamCode
                                ? "You can edit future rounds during the game"
                                : "ADD and Edit the Rounds of your Jam"}
                        </Text>
                    </Col>
                    <Row className='game-tabs' justify='space-between'>
                        <Col span={4}>
                            <Text className='jam-deck-rounds-field-title'>
                                Round
                            </Text>
                        </Col>

                        <Col span={14} style={{ display: "flex" }}>
                            {games.length > 0 &&
                                games.map((game, index) => {
                                    return (
                                        <Button
                                            onClick={() =>
                                                onTabChange(game.key)
                                            }
                                            className={
                                                game.key == currentRound
                                                    ? "selected-round-btn"
                                                    : "game-rounds-btn"
                                            }
                                            key={game?.key}
                                            disabled={
                                                game.status === "ENDED" ||
                                                editableRound
                                            }
                                        >
                                            {game?.key + 1}
                                        </Button>
                                    );
                                })}
                        </Col>
                        <Col span={6}>
                            <Row justify='end'>
                                <Col style={{ marginRight: 4 }}>
                                    {games.length > 1 &&
                                        activeGame?.key !== game?.key && (
                                            <Button
                                                size='small'
                                                className='jam-deck-rounds-add-btn-close'
                                                icon={
                                                    <CrossIcon
                                                        style={{ fontSize: 9 }}
                                                    />
                                                }
                                                onClick={() =>
                                                    setConfirmationModal(true)
                                                }
                                                disabled={
                                                    editableRound ||
                                                    games.length === 1 ||
                                                    activeGame?.key ===
                                                        game?.key
                                                }
                                            />
                                        )}
                                </Col>
                                <Col
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        size='small'
                                        className='jam-deck-rounds-add-btn'
                                        onClick={handleAddRound}
                                        disabled={
                                            games.length === 10 || editableRound
                                        }
                                    >
                                        Add <PlusOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {games.length > 0 && (
                        <GameForm
                            game={game}
                            index={currentRound}
                            update={gameUpdateHandler}
                            jamTime={jamTime}
                            tabActive={tabActive}
                            handleEditableRound={handleEditableRound}
                            editableRound={editableRound}
                            activeGame={activeGame}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default JamDeckCreateForm;
