import React from "react";
import { Modal, Row, Col, Typography, Button } from "antd";

const { Text } = Typography;

const ConfirmationModal = ({
    visible = true,
    onCancel,
    onOk,
    content,
    subContent,
    buttonText,
    buttonText2
}) => {
    return (
        <Modal
            className='confirmation-modal'
            title={false}
            open={visible}
            footer={false}
            onOk={onOk}
            onCancel={onCancel}
            bodyStyle={null}
            closable={false}
            centered={true}
        >
            <Row justify='center'>
                <Col sm={18} xs={22}>
                    <Text className='confirmation-modal-content'>
                        {content}
                    </Text>
                    <br />
                    <Text className='confirmation-modal-sub-content'>
                        {subContent}
                    </Text>
                </Col>

                <Col span={24}>
                    <Row
                        style={{
                            justifyContent: "space-between",
                            marginTop: 30,
                        }}
                    >
                        <Col sm={10} xs={11}>
                            <Button
                                className='medium-priority-btn sm-btn'
                                style={{ fontSize: 12 }}
                                onClick={onCancel}
                            >
                                {buttonText}
                            </Button>
                        </Col>
                        <Col sm={10} xs={11}>
                            <Button
                                className='high-priority-btn sm-btn'
                                style={{ fontSize: 12 }}
                                onClick={onOk}
                            >
                                {buttonText2}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default ConfirmationModal;
