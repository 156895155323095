import React, { useEffect } from "react";
import {isMobile} from 'react-device-detect';

const SoundEffectContainer = ({
    countDownModalVisible,
    toggleCountDownModalVisible, 
    togglePlay, 
    speakerVolume,
    lobbySoundRef,
    games,
    jamEndSoundRef,
    selectedSpeaker,
    activeGame,
    countDownRef,
    winnerSoundRef,
    somebodyCalledJindo,
    verifying,
    updateWinnerSongEnded,
    gameWinner,
}) => {
    const filteredGames = games.filter(game => game.gameId !== activeGame?.gameId && game.status === 'STARTING_SOON');

    useEffect(() => {
        if((!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) && !isMobile) {
            countDownRef.current.setSinkId(selectedSpeaker)
            verifying.current.setSinkId(selectedSpeaker)
            winnerSoundRef.current.setSinkId(selectedSpeaker)
        }
    }, [selectedSpeaker]);

    useEffect(() => {
        countDownRef.current.volume = speakerVolume * 0.45;
        winnerSoundRef.current.volume = speakerVolume * 0.35;
        somebodyCalledJindo.current.volume = speakerVolume;
        verifying.current.volume = speakerVolume;
    }, [speakerVolume]);

    useEffect(() => {
        if(countDownModalVisible) {
            lobbySoundRef.current.pause();
            countDownRef.current.play();
            countDownRef.current.onended = () => {
                toggleCountDownModalVisible();
                togglePlay();
            }
        }
        if(countDownModalVisible && isMobile) {
            setTimeout(() => {
                toggleCountDownModalVisible();
                // togglePlay();
            }, 18000)
        }
    }, [countDownModalVisible]);

    useEffect(() => {      
        if(activeGame.gameId === gameWinner?.winner?.gameId && gameWinner?.winner?.isFinished && gameWinner?.winner?.name) {
            lobbySoundRef.current.pause();
            somebodyCalledJindo.current.play();
        }
    }, [gameWinner]);

    useEffect(() => {
        somebodyCalledJindo.current.onended = () => {
            verifying.current.play();
        }
    }, []);

    useEffect(() => {
        verifying.current.onended = () => {
            winnerSoundRef.current.play();
        }
    }, []);

    useEffect(() => {
        winnerSoundRef.current.onended = () => {
            if(filteredGames.length > 0) {
                lobbySoundRef.current.play();
            } else {
                jamEndSoundRef.current.play();
            }
            updateWinnerSongEnded();
        }
    }, [activeGame]);

    return (<></>);
};

export default SoundEffectContainer;
